.container {
	justify-content: center;
	border-radius: 8px;
	text-align: center;
	overflow: hidden;
	position: relative;
	font-size: 1rem;
	color: black;
	cursor: pointer;
	box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
}

.container.closed {
	width: 6rem;
}

.container.expand.position {
	min-height: 5rem;
	width: 20rem;
}

.container.expand.group {
	min-height: 20rem;
	width: 20rem;
}

.upper {
	justify-content: center;
	padding: 5px;
	text-align: center;
	overflow: hidden;
	position: relative;
}

.upper.position {
	background-color: #3949ab;
}

.upper.group {
	background-color: #5e35b1;
	color: black;
}

.upper.closed {
	width: 6rem;
}

.upper.expand {
	width: 20rem;
}

.titleTag {
	text-align: center;
	margin-bottom: 0.3rem;
	padding: 0;
	font-weight: 500;
}

.titleTag.position {
	color: white;
}

.titleTag.group {
	color: white;
}

.titleTag.closed {
	font-size: 0.75rem;
}

.titleTag.expand {
	font-size: 1.25rem;
}

.lower.closed {
	height: 0rem;
}

.lower.expand {
	width: 20rem;
}

.lower p:not(:first-child) {
	margin-top: 40px;
}

div.expand {
	height: auto;
}

.orgTreeContainer {
	display: flex;
	flex-direction: column;
	width: 100%;
	margin: 0;
}

.orgTree {
	display: flex;
	flex-direction: column; /*Align children vertically*/
	align-items: flex-start;
	width: 100%;
	margin: 0;
}

.orgTree ul {
	/* border: 1px solid red; */
	margin: 0 0 0 1rem;
	padding: 0;
	/* margin-left: 1rem; */
	position: relative;
	transition: all 0.5s;
}

.orgTree li {
	/* border: 1px solid green; */
	padding: 0.2rem;
	padding-left: 1rem;
	display: flex;
	text-align: left;
	list-style-type: none;
	position: relative;
	align-items: center;
	width: auto;
}

/* Lines connecting nodes (you may need to adjust these for your specific layout) */
/* bar exiting a node*/
.orgTree ul ul::after {
	content: '';
	position: absolute;
	top: 50%;
	left: -1rem;
	border-top: 2px solid lightgrey;
	width: 1rem;
}

/* vertical bar for all children nodes */
.orgTree ul li::after {
	content: '';
	position: absolute;
	left: 0;
	top: 0;
	border-left: 2px solid lightgrey;
	height: 100%;
	width: 1rem;
}

/* bar entering all children nodes */
.orgTree ul li::before {
	content: '';
	position: absolute;
	left: 0;
	top: 50%;
	border-top: 2px solid lightgrey;
	height: 100%;
	width: 1rem;
}

.orgTree ul li:first-child:before,
.orgTree ul li:last-child::before {
	width: 0;
}

/* vertical bar and bar entering nodes for all first children */
.orgTree ul li:first-child::after {
	content: '';
	position: absolute;
	left: 0;
	top: 50%;
	border-left: 2px solid lightgrey;
	border-top: 2px solid lightgrey;
	border-radius: 5px 0 0 0;
	height: 50%;
	width: 0.9rem;
}

/* vertical bar and bar entering nodes for all last children */
.orgTree ul li:last-child::after {
	content: '';
	position: absolute;
	left: 0;
	top: 0;
	border-left: 2px solid lightgrey;
	border-bottom: 2px solid lightgrey;
	transform: translateY(0px);
	border-radius: 0 0 0 5px;
	height: 50%;
	width: 0.9rem;
}

/* entering nodes for only child nodes */
.orgTree ul li:only-child::after {
	content: '';
	position: absolute;
	left: 0;
	top: 50%;
	border-radius: 0 0 0 0;
	border-top: none;
	height: 0rem;
	width: 0.9rem;
}

.orgTree > ul > li:first-child::after,
.orgTree > ul > li:last-child::after,
.orgTree > ul > li:only-child::after,
.orgTree > ul > li::before {
	content: none;
}

.orgTree li a {
	border: 1px solid #ccc;
	padding: 5px 10px;
	text-decoration: none;
	color: #666;
	font-family: arial, verdana, tahoma;
	font-size: 11px;
	display: inline-block;

	border-radius: 5px;
	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;

	transition: all 0.5s;
	-webkit-transition: all 0.5s;
	-moz-transition: all 0.5s;
}

.orgTree li a:hover,
.orgTree li a:hover + ul li a {
	background: #c8e4f8;
	color: #000;
	border: 1px solid #94a0b4;
}

.orgTree li a:hover + ul li::after,
.orgTree li a:hover + ul li::before,
.orgTree li a:hover + ul::before,
.orgTree li a:hover + ul ul::before {
	border-color: #94a0b4;
}
