.container {
	display: block;
	width: 100%;
	margin: 0.3rem 0;
	height: 100%;
	background-color: #7baaf150;
	box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
	border-radius: 16px;
	overflow: hidden;
}

.container:hover {
	background-color: #ddf3e0;
	box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
	border-radius: 16px;
	cursor: pointer;
}

.justify {
	display: flex;
	justify-content: space-evenly;
	margin: auto;
}

/* .imgContainer {
	height: auto;
	margin-top: auto;
	margin-bottom: auto;
	justify-content: center;
	display: flex;
	align-items: center;
	align-content: center;
} */

.memberPhoto {
	display: flex;
	margin: auto;
	border-radius: 90px;
	width: 100px;
}

.memberCard {
	width: 100%;
	overflow: hidden;
}

.memberName {
	display: block;
	margin: 0.5rem 0.5rem 0 0;
	color: #27354b;
	font-weight: 500;
	font-size: 1rem;
	text-align: center;
	width: 100%;
}

.infoGroup {
	padding-top: 0.5rem;
	display: block;
	justify-content: left;
	text-align: left;
	padding-left: 0.5rem;
}

.memberType {
	display: block;
	margin: auto;
	font-weight: 500;
	font-size: 0.85rem;
	text-align: center;
	border-radius: 16px;
	width: 70%;
	/* color: black; */
	color: navy;
	background-color: #80b3ff;
}

.memberInfo {
	display: block;
	margin: auto;
	color: #27354b;
	font-weight: 500;
	font-size: 0.8rem;
}

.memberEmail {
	display: flex;
	margin: auto;
	font-size: 0.8rem;
}

.memberEmailOrderBy {
	display: block;
	margin: 0.5rem 0.5rem 0 0;
	color: #27354b;
	font-weight: 500;
	font-size: 1rem;
	text-align: center;
	width: 100%;
}

.memberNameOrderBy {
	display: flex;
	margin: auto;
	font-size: 0.8rem;
}

.email,
.email:visited,
.email:hover {
	color: blue;
}
