.signatureCard {
	border: 2px solid#3b3d80;
	border-radius: 16px;
	padding: 1rem;
	margin: 0 1rem;
}

.signatureHeader {
	color: #3b3d80;
	font-weight: 500;
	font-size: 1rem;
	margin: 0 1.5rem;
}

.img {
	border: none;
	border-radius: 90px;
}
