.usergriditem {
    width: 80% !important;
    margin-left: auto !important;
    margin-right: auto !important;
}
.userinfo img {
    border: 1px solid #9bbbf5;
    border-radius: 3px;
    padding: 3px;
    max-width: 300px;
    transition: 100ms ease-in-out;
    width: 100%;
}
.userinfo img:hover {
    cursor: pointer !important;
}
.userinfo img:hover {
    filter: brightness(1.1);
}
.userinfo {
    font-size: 1em;
    font-weight: 400;
}
.userinfo p {
    text-align: center;
}
.userinfo a {
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5;
    letter-spacing: 0.00938em;
    color: rgba(0, 0, 0, 0.6);
    text-decoration: none;
}
.userinfo a:hover {
    text-decoration: underline;
}
.userinfo h3 {
    font-size: 1.5em;
    line-height: 1.7em;
    font-weight: 700;
    margin-top: 10px;
    margin-bottom: 5px;
    color: #585ba3;
}
